import React from 'react';

const Burger = ({color, width, height}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 29 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs></defs>
        <g id="Page-5" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
            <g id="Staffer_DesktopCreative_v1.6" transform="translate(-1165.000000, -80.000000)" stroke={color} stroke-width="2">
                <g id="Group-729" transform="translate(856.000000, 66.990000)">
                    <g id="Group-2" transform="translate(310.000000, 14.000000)">
                        <path d="M27,0.1866 L0,0.1866" id="Stroke-723"></path>
                        <path d="M27,8.1866 L0,8.1866" id="Stroke-725"></path>
                        <path d="M27,16.1866 L0,16.1866" id="Stroke-727"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  )
}

export default Burger;
