import React, { Component } from 'react';

import Arrow from './arrow';
import Burger from './burger';
import Facebook from './facebook';
import Instagram from './instagram';
import LinkedIn from './linkedIn';
import Profile from './profile';
import Search from './search';
import Twitter from './twitter';
import Warning from './warning';

export default class Icon extends Component {
  render() {
    switch (this.props.type) {
      case "arrow":
        return <Arrow id={this.props.id} color={this.props.color} width={this.props.width} height={this.props.height} />
      case "burger":
        return <Burger color={this.props.color} width={this.props.width} height={this.props.height} />
      case "facebook":
        return <Facebook onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave} color={this.props.color} width={this.props.width} height={this.props.height} filled={this.props.filled} />
      case "instagram":
        return <Instagram onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave} color={this.props.color} width={this.props.width} height={this.props.height} filled={this.props.filled} />
      case "twitter":
        return <Twitter onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave} color={this.props.color} width={this.props.width} height={this.props.height} filled={this.props.filled} />
      case "linkedIn":
        return <LinkedIn onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave} color={this.props.color} width={this.props.width} height={this.props.height} filled={this.props.filled} />
      case "profile":
        return <Profile onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave} color={this.props.color} width={this.props.width} height={this.props.height} />
      case "search":
        return <Search color={this.props.color} width={this.props.width} height={this.props.height} />
      case "warning":
        return <Warning color={this.props.color} width={this.props.width} height={this.props.height} />
      default:
        return null
    }
  }
}
