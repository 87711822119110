import React from 'react'

const Warning = ({color, width, height}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 19 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs></defs>
        <g id="Page-5" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Staffer_DesktopCreative_v1.6" transform="translate(-91.000000, -1444.000000)" fill={color}>
                <g id="Group-869" transform="translate(91.000000, 1443.990000)">
                    <path d="M9.6458,1.8211 C9.4358,1.8211 9.2078,2.0071 9.0208,2.3331 L2.0338,14.4331 C1.8458,14.7591 1.7988,15.0501 1.9028,15.2311 C2.0078,15.4121 2.2838,15.5171 2.6598,15.5171 L16.6318,15.5171 C17.0078,15.5171 17.2838,15.4121 17.3878,15.2311 C17.4928,15.0501 17.4458,14.7591 17.2578,14.4331 L10.2708,2.3331 C10.0838,2.0081 9.8558,1.8211 9.6458,1.8211 M16.6318,16.6201 L2.6598,16.6201 C1.8788,16.6201 1.2548,16.3151 0.9478,15.7831 C0.6408,15.2511 0.6888,14.5581 1.0788,13.8821 L8.0648,1.7821 C8.4558,1.1061 9.0318,0.7181 9.6458,0.7181 C10.2598,0.7181 10.8358,1.1061 11.2268,1.7821 L18.2128,13.8821 C18.6028,14.5581 18.6508,15.2511 18.3438,15.7831 C18.0368,16.3151 17.4128,16.6201 16.6318,16.6201" id="Fill-863"></path>
                    <path d="M9.0059,6.2536 C9.0059,5.9006 9.2929,5.6136 9.6459,5.6136 C9.9989,5.6136 10.2859,5.9006 10.2859,6.2536 L10.2859,10.5186 C10.2859,10.8726 9.9989,11.1586 9.6459,11.1586 C9.2929,11.1586 9.0059,10.8726 9.0059,10.5186 L9.0059,6.2536 Z" id="Fill-865"></path>
                    <path d="M9.6458,12.1512 C10.1758,12.1512 10.6058,12.5812 10.6058,13.1112 C10.6058,13.6412 10.1758,14.0712 9.6458,14.0712 C9.1158,14.0712 8.6858,13.6412 8.6858,13.1112 C8.6858,12.5812 9.1158,12.1512 9.6458,12.1512" id="Fill-867"></path>
                </g>
            </g>
        </g>
    </svg>
  )
}

export default Warning;
