import React from 'react';

const Facebook = ({ color, filled, width, height, onMouseEnter, onMouseLeave }) => {
  if (filled) {
    return (
      <svg onMouseEnter={onMouseEnter !== undefined ? () => onMouseEnter('#facebook') : null} onMouseLeave={onMouseLeave !== undefined ? () => onMouseLeave('#facebook') : null} id="facebook" width={width} height={height} viewBox="0 0 31 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs></defs>
        <g id="Page-5" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Staffer_DesktopCreative_v1.6" transform="translate(-772.000000, -1225.000000)" fill={color}>
            <g id="Group-862" transform="translate(91.000000, 146.990000)">
              <path d="M681.6849,1099.381 L688.1369,1099.381 L688.1369,1093.111 C688.1369,1090.345 688.2059,1086.08 690.2139,1083.439 C692.3309,1080.641 695.2349,1078.74 700.2319,1078.74 C708.3719,1078.74 711.7989,1079.902 711.7989,1079.902 L710.1849,1089.462 C710.1849,1089.462 707.4979,1088.683 704.9879,1088.683 C702.4779,1088.683 700.2319,1089.583 700.2319,1092.09 L700.2319,1099.381 L710.5219,1099.381 L709.8049,1108.719 L700.2319,1108.719 L700.2319,1141.155 L688.1369,1141.155 L688.1369,1108.719 L681.6849,1108.719 L681.6849,1099.381 Z" id="Fill-837"></path>
            </g>
          </g>
        </g>
      </svg>
    )
  } else {
    return (
      <svg width={width} height={height} viewBox="0 0 33 66" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs></defs>
        <g id="Page-5" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Staffer_DesktopCreative_v1.6" transform="translate(-771.000000, -1344.000000)" fill={color}>
            <g id="Group-862" transform="translate(91.000000, 146.990000)">
              <path d="M689.1367,1260.2516 L699.2317,1260.2516 L699.2317,1228.8146 C699.2317,1228.2626 699.6787,1227.8146 700.2317,1227.8146 L708.8787,1227.8146 L709.4427,1220.4766 L700.2317,1220.4766 C699.6787,1220.4766 699.2317,1220.0296 699.2317,1219.4766 L699.2317,1212.1866 C699.2317,1209.4266 701.3837,1207.7786 704.9887,1207.7786 C706.6297,1207.7786 708.3187,1208.0866 709.3787,1208.3256 L710.6597,1200.7336 C709.2347,1200.4116 705.8917,1199.8356 700.2317,1199.8356 C696.1057,1199.8356 693.2627,1201.1626 691.0117,1204.1376 C689.1367,1206.6036 689.1367,1210.8996 689.1367,1213.2076 L689.1367,1219.4766 C689.1367,1220.0296 688.6897,1220.4766 688.1367,1220.4766 L682.6847,1220.4766 L682.6847,1227.8146 L688.1367,1227.8146 C688.6897,1227.8146 689.1367,1228.2626 689.1367,1228.8146 L689.1367,1260.2516 Z M700.2317,1262.2516 L688.1367,1262.2516 C687.5837,1262.2516 687.1367,1261.8036 687.1367,1261.2516 L687.1367,1229.8146 L681.6847,1229.8146 C681.1317,1229.8146 680.6847,1229.3666 680.6847,1228.8146 L680.6847,1219.4766 C680.6847,1218.9246 681.1317,1218.4766 681.6847,1218.4766 L687.1367,1218.4766 L687.1367,1213.2076 C687.1367,1210.6636 687.1367,1205.9296 689.4177,1202.9296 C692.0457,1199.4546 695.4827,1197.8356 700.2317,1197.8356 C708.4427,1197.8356 711.9737,1199.0006 712.1197,1199.0506 C712.5847,1199.2076 712.8657,1199.6796 712.7847,1200.1636 L711.1717,1209.7246 C711.1237,1210.0056 710.9587,1210.2526 710.7177,1210.4046 C710.4757,1210.5566 710.1797,1210.5966 709.9077,1210.5186 C709.8817,1210.5116 707.3197,1209.7786 704.9887,1209.7786 C703.2747,1209.7786 701.2317,1210.1966 701.2317,1212.1866 L701.2317,1218.4766 L710.5227,1218.4766 C710.8007,1218.4766 711.0677,1218.5936 711.2557,1218.7976 C711.4457,1219.0016 711.5407,1219.2756 711.5197,1219.5536 L710.8017,1228.8916 C710.7617,1229.4126 710.3267,1229.8146 709.8047,1229.8146 L701.2317,1229.8146 L701.2317,1261.2516 C701.2317,1261.8036 700.7837,1262.2516 700.2317,1262.2516 Z" id="Fill-846"></path>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export default Facebook;
