import React from 'react';

const Arrow = ({color, width, height, id}) => {
  return (
    <svg id={id} width={width} height={height} viewBox="0 0 25 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs></defs>
      <g id="Page-5" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Staffer_DesktopCreative_v1.6" transform="translate(-1014.000000, -1010.000000)" fill={color}>
          <polygon id="Fill-827" points="1014.2457 1022.3611 1034.1897 1022.3611 1027.0647 1029.5941 1028.8967 1031.3611 1039.0637 1021.0061 1028.6437 1010.8361 1026.8747 1012.6691 1034.2067 1019.8381 1014.2457 1019.8381"></polygon>
        </g>
      </g>
    </svg>
  )
}

export default Arrow;
