import React from 'react';

const Search = ({ color, width, height, onMouseEnter, onMouseLeave }) => {
    return (
      <svg onMouseEnter={onMouseEnter !== undefined ? () => onMouseEnter('#search') : null} onMouseLeave={onMouseLeave !== undefined ? () => onMouseLeave('#search') : null} id="search" width={width} height={height} viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <defs></defs>
          <g id="Page-5" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Staffer_DesktopCreative_v1.6" transform="translate(-983.000000, -79.000000)" fill={color}>
                  <path d="M996.8088,91.1797 C997.8078,89.9277 998.3558,88.3407 998.3558,86.7357 C998.3558,82.7807 995.1258,79.5497 991.1498,79.5497 C987.1768,79.5497 983.9658,82.7807 983.9658,86.7357 C983.9658,90.6907 987.1968,93.9207 991.1508,93.9207 C992.7378,93.9207 994.3218,93.3717 995.5948,92.3737 L1001.1558,98.2367 L1002.3898,97.0037 L996.8088,91.1797 Z M991.1508,92.2177 C988.1548,92.2177 985.7078,89.7697 985.7078,86.7757 C985.7078,83.7797 988.1548,81.3317 991.1508,81.3317 C994.1468,81.3317 996.5948,83.7797 996.5948,86.7757 C996.5948,89.7697 994.1468,92.2177 991.1508,92.2177 Z" id="Fill-711"></path>
              </g>
          </g>
      </svg>
    )
}

export default Search;
