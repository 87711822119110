import React from 'react';

const Profile = ({ color, width, height, onMouseEnter, onMouseLeave }) => {
    return (
        <svg onMouseEnter={onMouseEnter !== undefined ? () => onMouseEnter('#profile') : null} onMouseLeave={onMouseLeave !== undefined ? () => onMouseLeave('#profile') : null} id="profile" width={width} height={height} viewBox="0 0 18 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs></defs>
            <g id="Page-5" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Staffer_DesktopCreative_v1.6" transform="translate(-1098.000000, -78.000000)" fill={color}>
                    <g id="Group-729" transform="translate(856.000000, 66.990000)">
                        <g id="Group" transform="translate(242.000000, 11.000000)">
                            <path d="M9.0215,10.9033 C7.6885,10.9033 6.4105,10.3743 5.4685,9.4313 C4.5265,8.4893 3.9965,7.2123 3.9965,5.8793 C3.9965,4.5473 4.5265,3.2683 5.4675,2.3263 C6.4095,1.3833 7.6865,0.8533 9.0205,0.8533 C10.3525,0.8533 11.6305,1.3823 12.5735,2.3233 C13.5165,3.2653 14.0455,4.5443 14.0465,5.8763 C14.0455,7.2093 13.5155,8.4863 12.5735,9.4293 C11.6305,10.3713 10.3545,10.9013 9.0215,10.9033 Z M9.0215,1.9593 C7.9825,1.9593 6.9855,2.3733 6.2505,3.1073 C5.5155,3.8423 5.1025,4.8383 5.1025,5.8793 C5.1025,6.9183 5.5155,7.9153 6.2515,8.6503 C6.9855,9.3843 7.9835,9.7973 9.0235,9.7973 C10.0625,9.7973 11.0595,9.3823 11.7935,8.6473 C12.5285,7.9123 12.9405,6.9153 12.9405,5.8753 C12.9395,4.8363 12.5265,3.8413 11.7905,3.1063 C11.0575,2.3713 10.0605,1.9593 9.0215,1.9563 L9.0215,1.9593 Z" id="Fill-717"></path>
                            <path d="M13.2126,10.4951 L12.8966,10.3211 L12.6116,10.5421 C12.4046,10.7021 12.1886,10.8491 11.9636,10.9801 L12.9836,11.6391 C15.2696,13.0261 16.6636,15.5041 16.6636,18.1771 L16.6636,18.2491 L17.7606018,18.2491 L17.7606,18.1771 C17.7616,16.6051 17.3396,15.0641 16.5396,13.7121 C15.7386,12.3601 14.5896,11.2491 13.2126,10.4951" id="Fill-719"></path>
                            <path d="M6.0908,10.9758 C5.8618,10.8438 5.6428,10.6978 5.4318,10.5368 L5.1468,10.3168 L4.8308,10.4908 C3.4508,11.2438 2.2998,12.3558 1.4978,13.7078 C0.6958,15.0588 0.2728,16.6028 0.2728,18.1748 L0.2728,18.2458 L1.3698,18.2458 L1.3698,18.1748 L1.3708,18.1748 C1.3758,15.4988 2.7788,13.0198 5.0708,11.6378 L6.0908,10.9758 Z" id="Fill-721"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default Profile;
